<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('link')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tìm bằng Link</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('uid')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Tìm bằng Uid</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link trang cá nhân cần quét SĐT:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="uid_input"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Link trang cá nhân cần quét SĐT"
                                />
                                <div class="row form-group mt-4">
                                    <div class="col-12 text-center bold">
                                        <div class="card card-info">
                                            <div class="card-body">
                                                <h5 class="font-bold">
                                                    Tổng:
                                                    <span class="bold"
                                                        ><number
                                                            ref="number1"
                                                            :from="0"
                                                            :to="price"
                                                            :format="theFormat"
                                                            :duration="1"
                                                            :delay="0"
                                                            easing="Power1.easeOut"
                                                        />
                                                        {{ currency }}</span
                                                    >
                                                </h5>
                                                <h6 class="mb-0">
                                                    Bạn sẽ tìm <span class="bold">1 SĐT</span> với giá
                                                    <span class="green bold">{{ price | formatNumber }}</span>
                                                    {{ currency }} / SĐT
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    @click="convert()"
                                    :class="['btn btn-block btn-dark-blue btn-rounded bold mt-4']"
                                    type="submit"
                                >
                                    Tiến hành
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div v-if="scan_type === 'link' && result_link.phone" class="row">
                            <div class="col-12">
                                <div class="card card-white">
                                    <div class="card-body">
                                        <h4 class="text-success bold text-center mb-3">
                                            THÀNH CÔNG
                                        </h4>
                                        <div class="text-center">
                                            <img
                                                :src="
                                                    'https://graph.facebook.com/' +
                                                        result_link.fb_id +
                                                        '/picture?width=200&height=200'
                                                "
                                                class="img-circle img-avatar"
                                                width="100"
                                            />
                                            <h5 v-if="result_link.fb_name" class="mt-3">
                                                Facebook:
                                                <span class="font-bold text-primary">{{ result_link.fb_name }}</span>
                                            </h5>
                                            <h5 :class="result_link.fb_name ? '' : 'mt-3'">
                                                SĐT:
                                                <span class="font-bold text-info">{{ result_link.phone }}</span>
                                            </h5>
                                            <h5>
                                                Fb ID:
                                                <span class="font-bold text-success">{{ result_link.fb_id }}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">List Uid cần quét:</h6>
                            </div>
                            <div class="col-md col-12">
                                <textarea
                                    v-model="uid_input"
                                    rows="5"
                                    class="form-control input-light"
                                    required
                                    placeholder="Nhập ID tài khoản cần quét sdt, mỗi ID cách nhau 1 dòng"
                                ></textarea>
                                <div class="row form-group mt-4">
                                    <div class="col-12 text-center bold">
                                        <div class="card card-info">
                                            <div class="card-body">
                                                <h5 class="font-bold">
                                                    Tổng:
                                                    <span class="bold"
                                                        ><number
                                                            ref="number1"
                                                            :from="0"
                                                            :to="price * list_uid.length"
                                                            :format="theFormat"
                                                            :duration="1"
                                                            :delay="0"
                                                            easing="Power1.easeOut"
                                                        />
                                                        {{ currency }}</span
                                                    >
                                                </h5>
                                                <h6 class="mb-0">
                                                    Bạn sẽ tìm <span class="bold">{{ list_uid.length }} SĐT</span> với
                                                    giá <span class="green bold">{{ price | formatNumber }}</span>
                                                    {{ currency }} / SĐT
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    @click="convert()"
                                    :class="['btn btn-block btn-dark-blue btn-rounded bold mt-4']"
                                    type="submit"
                                >
                                    Tiến hành
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <datatable title="Kết quả" stt="true" :columns="columnsTableLogs" :rows="list_success">
                        </datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import datatable from "../../../../components/datatable/datatable"
import { convertUsernameToFbId } from "../../../../api/services/facebook"
import { convertPhoneUID } from "../../../../api/services/other"
export default {
    name: "spam-sms-order",
    components: { datatable },
    data() {
        return {
            uid_input: "",
            scan_type: "link",
            price: 0,
            result_link: {
                fb_id: "",
                fb_name: "",
                phone: ""
            },
            result_uid: [],
            columnsTableLogs: [
                {
                    label: "Avatar",
                    field: "fb_id",
                    avatar: true,
                    avatar_size: "40"
                },
                {
                    label: "Fb ID",
                    field: "fb_id",
                    fb_link: true
                },
                {
                    label: "SDT",
                    field: "phone"
                }
            ]
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 56)
            return tool.prices
        },
        list_uid() {
            return this.uid_input ? this.uid_input.trim().match(/.+/g) : []
        },
        list_success() {
            return this.result_uid.filter(item => item.phone)
        }
    },
    watch: {
        async uid_input() {
            this.result_link = {
                fb_id: "",
                fb_name: "",
                phone: ""
            }
            this.result_uid = []
            if (this.uid_input.indexOf("facebook.com") > -1) {
                await this.getFbId(this.uid_input)
            }
        }
    },
    async created() {
        this.getPriceSeedingType()
    },
    methods: {
        convert: async function() {
            this.result_uid = []
            this.result_link.fb_id = this.uid_input
            let countSuccess = 0
            let data = await convertPhoneUID({
                object_id: this.list_uid,
                type: "fbid"
            })
            if (data.data) {
                data.data.forEach(data => {
                    for (const key in data) {
                        if (data[key] && !data[key].error) {
                            this.result_link.phone = data[key]
                            this.result_link.fb_id = key
                            this.result_uid.push({
                                fb_id: key,
                                phone: data[key]
                            })
                            countSuccess++
                        } else {
                            this.result_uid.push(data)
                        }
                    }
                })
            }
            countSuccess > 0
                ? this.$swal("Hoàn thành", `Đã convert thành công ${countSuccess} uid`, "success")
                : this.$swal("Thất bại", "Convert không thành công, vui lòng thử uid khác", "error")
            // this.$store.dispatch("USER_GET_INFO")
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.uid_input = regex[1]
                        return true
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.uid_input = data.data.id
                        this.result_link.fb_name = data.data.name
                        return true
                    } else {
                        return false
                    }
                }
            } else return true
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getPriceSeedingType: function() {
            let price = this.prices.find(price => price.package_name.includes("convert_uid_to_phone"))
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        },
        handleShowPanel: async function(panel) {
            this.scan_type = panel
        }
    }
}
</script>
